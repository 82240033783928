<template>
  <div class="af-card--user">
    <p class="has-text-weight-bold">
      {{ $t("af:my_todos.active.title") }}
    </p>

    <p class="has-text-grey-icon mb-5">
      {{ $t("af:my_todos.active.subtitle") }}
    </p>

    <template v-if="loaded">
      <template v-if="todos.length === 0">
        <div class="empty-todo-card mx-0 my-2 has-text-centered pb-5 pt-5">
          <b-icon
            icon-pack="fas"
            icon="info-circle"
            type="is-grey-icon"
            size="is-large"
          />

          <p class="has-text-weight-bold">
            <label>{{ $t("af:my_todos.active.empty.title") }}</label>
          </p>
        </div>
      </template>

      <template v-else>
        <div v-for="todo in todos" :key="todo.id">
          <user-todo-card :todo="todo" active-card />
        </div>
      </template>
    </template>

    <loading-skeleton v-else />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { TODO_TYPE } from "@/utils/const";
import LoadingSkeleton from "../../LoadingSkeleton";
import UserTodoCard from "./UserTodoCard";
import { cloneDeep } from "lodash";

export default {
  name: "UserActiveTodos",
  components: { LoadingSkeleton, UserTodoCard },
  data() {
    return {
      TODO_TYPE,
      loaded: false,
    };
  },
  computed: {
    ...mapGetters({
      loanTodos: "myTodo/getActives",
      mortgageTodos: "myTodo/getMortgageActives",
    }),
    todos() {
      const extendedLoanTodos = cloneDeep(this.loanTodos);
      extendedLoanTodos.forEach((todo) => {
        todo.mortgage = false;
      });

      const extendedMortgageTodos = cloneDeep(this.mortgageTodos);
      extendedMortgageTodos.forEach((todo) => {
        todo.mortgage = true;
      });

      return extendedLoanTodos.concat(extendedMortgageTodos);
    },
  },
  async mounted() {
    await this.$store.dispatch("myTodo/fetchCount");
    await this.$store.dispatch("myTodo/fetchMortgageCount");
    await this.$store.dispatch("myTodo/fetchActives");
    await this.$store.dispatch("myTodo/fetchMortgageActives");
    this.loaded = true;
  },
  methods: {},
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.empty-todo-card {
  background: $grey-2;
  border-radius: 8px;
  padding: 1rem;
}
</style>
