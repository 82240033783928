<template>
  <div
    class="todo-card mx-0 my-2 pb-5 pt-5 mt-4"
    :class="{ 'is-clickable': canClick }"
    @click="onClick"
  >
    <div class="is-flex">
      <b-icon
        icon-pack="fas"
        :icon="TODO_TYPE.ICON[todo.type]"
        type="is-grey-icon"
        size="is-large"
      />

      <div class="ml-4">
        <div class="mb-1">
          <label class="has-text-weight-bold">
            <template v-if="todo.title">
              {{ todo.title }}
            </template>
            <template v-else>
              {{ $t(TODO_TYPE.TITLE[todo.type]) }}
            </template>
          </label>
        </div>

        <div class="todo-card-description">
          <label class="todo-type-badge">{{
            $t(TODO_TYPE.NAME[todo.type])
          }}</label>

          <label class="has-text-grey-icon todo-date-label">
            <template v-if="todo.dueDate">
              {{ $t("af:my_todos.card.label.due_date") }}
              {{ formatDateTime(todo.dueDate) }}
            </template>

            <template v-else>
              {{ $t("af:my_todos.card.label.created") }}
              {{ formatDateTime(todo.created) }}
            </template>
          </label>
        </div>
      </div>
    </div>

    <span class="af-text-button">
      <label class="has-text-blue" v-if="activeCard">
        {{ $t(TODO_TYPE.BUTTON[todo.type]) }}
      </label>

      <label class="has-text-grey" v-else>
        {{ $t(TODO_TYPE.STATUS[todo.type][todo.status]) }}
      </label>
    </span>
  </div>
</template>

<script>
import { CORRECTION_STATUS, TODO_TYPE } from "@/utils/const";
import { formatDateTime } from "@/utils/util";

export default {
  name: "UserTodoCard",
  props: {
    todo: {
      type: Object,
      required: true,
    },
    activeCard: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      TODO_TYPE,
      CORRECTION_STATUS,
      formatDateTime,
    };
  },
  computed: {
    canClick() {
      return (
        (this.todo.type === TODO_TYPE.ENUM.CORRECTION &&
          this.todo.status !== CORRECTION_STATUS.CANCELLED &&
          this.todo.status !== CORRECTION_STATUS.WITHDRAWN) ||
        (this.todo.type === TODO_TYPE.ENUM.SIGNATURE && this.activeCard)
      );
    },
  },
  methods: {
    onClick() {
      if (this.canClick) {
        if (this.todo.type === TODO_TYPE.ENUM.CORRECTION) {
          this.$router.push({
            name: "UserSubmitMissingDocuments",
            params: {
              id: this.todo.id,
              mortgage: this.todo.mortgage,
            },
          });
        } else if (this.todo.type === TODO_TYPE.ENUM.SIGNATURE) {
          if (this.todo.mortgage) {
            this.$router.push({
              name: "MortgageApplication",
            });
          } else {
            this.$router.push({
              name: "LoanApplication",
            });
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.todo-card {
  background: $grey-2;
  border-radius: 8px;
  padding: 0.1rem 0.5rem;
  display: flex;
  flex-direction: column;
}

.todo-type-badge {
  background-color: $cyan;
  padding: 0.2rem 0.7rem;
  border-radius: 6px;
  color: white;
  font-size: 14px;
}

.todo-date-label {
  font-weight: 500;
  font-size: 15px;
  margin-top: 1rem;
  display: inline-block;
}

.af-text-button {
  font-weight: 500;
  margin: 0 auto;
  cursor: inherit;
}

.todo-card-description {
  margin-top: 1rem;
}

label {
  cursor: inherit;
}

@media only screen and (min-width: 769px) {
  .todo-card {
    flex-direction: row;
    padding: 0.5rem;
  }

  .af-text-button {
    margin-left: auto;
    margin-right: 0;
  }

  .todo-card-description {
    margin-top: 0;
  }

  .todo-date-label {
    margin-top: 0;
    margin-left: 0.5rem;
  }
}
</style>
